import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { authSignup } from "../store/actions/auth";

const RegistrationForm = ({ signup, error, loading, token }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password1: "",
    password2: ""
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, email, password1, password2 } = formData;
    signup(username, email, password1, password2);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const { username, email, password1, password2 } = formData;

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Signup to your account
        </Header>
        {error && <p>{error.message}</p>}

        <React.Fragment>
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input
                onChange={handleChange}
                value={username}
                name="username"
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username"
              />
              <Form.Input
                onChange={handleChange}
                value={email}
                name="email"
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="E-mail address"
              />
              <Form.Input
                onChange={handleChange}
                fluid
                value={password1}
                name="password1"
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
              <Form.Input
                onChange={handleChange}
                fluid
                value={password2}
                name="password2"
                icon="lock"
                iconPosition="left"
                placeholder="Confirm password"
                type="password"
              />

              <Button
                color="teal"
                fluid
                size="large"
                loading={loading}
                disabled={loading}
              >
                Signup
              </Button>
            </Segment>
          </Form>
          <Message>
            Already have an account? <NavLink to="/login">Login</NavLink>
          </Message>
        </React.Fragment>
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  token: state.auth.token
});

const mapDispatchToProps = (dispatch) => ({
  signup: (username, email, password1, password2) =>
    dispatch(authSignup(username, email, password1, password2))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
