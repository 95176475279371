import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";

import { BlogDetailURL } from "../constants";

const BlogDetail = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        axios.get(BlogDetailURL(postId))
            .then(response => setPost(response.data))
            .catch(error => console.error('Error fetching blog post:', error));
    }, [postId]);

    if (!post) return <div>Loading...</div>;

    return (
        <div>
            <h1>{post.title}</h1>
            <p>{post.content}</p>
        </div>
    );
};

export default BlogDetail;
