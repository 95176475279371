import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { authAxios } from "../utils";
import {
  Container,
  Dimmer,
  Image,
  Item,
  Label,
  Loader,
  Message,
  Segment
} from "semantic-ui-react";
import { productListURL, addToCartURL } from "../constants";
import { fetchCart } from "../store/actions/cart";
const ProductList = ({ refreshCart }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(productListURL)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const handleAddToCart = slug => {
    setLoading(true);
    authAxios
      .post(addToCartURL, { slug })
      .then(res => {
        refreshCart();
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  return (
    <Container>
      {error && (
        <Message
          error
          header="There was some errors with your submission"
          content={JSON.stringify(error)}
        />
      )}
      {loading && (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>

          <Image src="/images/wireframe/short-paragraph.png" />
        </Segment>
      )}
      <Item.Group divided>
        {data.map(item => {
          return (
            <Item key={item.id}>
              <Item.Image src={item.image} />
              <Item.Content>
                <Item.Header
                  as="a"
                  onClick={() => navigate(`/products/${item.id}`)}
                >
                  {item.title}
                </Item.Header>
                <Item.Meta>
                  <span className="cinema">{item.category}</span>
                </Item.Meta>
                <Item.Description>{item.description}</Item.Description>
                <Item.Extra>
                  {
                  /* \<Button
                    primary
                    floated="right"
                    icon
                    labelPosition="right"
                    onClick={() => handleAddToCart(item.slug)}
                  >
                    Add to cart
                    <Icon name="cart plus" />
                  </Button> */
                  }
                  {item.discount_price && (
                    <Label
                      color={
                        item.label === "primary"
                          ? "blue"
                          : item.label === "secondary"
                          ? "green"
                          : "olive"
                      }
                    >
                      {item.label}
                    </Label>
                  )}
                </Item.Extra>
              </Item.Content>
            </Item>
          );
        })}
      </Item.Group>
    </Container>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    refreshCart: () => dispatch(fetchCart())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ProductList);
