import React, { useState, useEffect } from "react";
import {
  Container,
  Dimmer,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Table,
  Button,
  Message,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToCartURL,
  orderSummaryURL,
  orderItemDeleteURL,
  orderItemUpdateQuantityURL
} from "../constants";
import { authAxios } from "../utils";

const OrderSummary = ({ isAuthenticated }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    handleFetchOrder();
  }, [isAuthenticated]);

  const handleFetchOrder = () => {
    setLoading(true);
    authAxios
      .get(orderSummaryURL)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError("You currently do not have an order");
          setLoading(false);
        } else {
          setError(err);
          setLoading(false);
        }
      });
  };

  const renderVariations = (orderItem) => {
    let text = "";
    orderItem.item_variations.forEach((iv) => {
      text += `${iv.variation.name}: ${iv.pretty_name}, `;
    });
    return text;
  };

  const renderCustomizations = (orderItem) => {
    let text = "";
    orderItem.customizations.forEach((customization) => {
      text += `${customization.item_customization.name}: ${customization.value}, `;
    });
    return text;
  };
  
  const parseUVariations = (itemVariations) => {
    return itemVariations.flatMap((itemVariation) => {
      return { id: itemVariation.variation.id, value_id: itemVariation.id };
    }, []);
  };

  const parseCustomizations = (orderCustomizations) => {
    return orderCustomizations.flatMap((orderCustomization) => {
      return { id: orderCustomization.item_customization.id, value: orderCustomization.value };
    }, []);
  };

  const handleAddToCart = (slug, itemVariations, orderCustomizations) => {
    setLoading(true);
    const variations = parseUVariations(itemVariations);
    const customizations = parseCustomizations(orderCustomizations);
    authAxios
      .post(addToCartURL, { slug, variations, customizations })
      .then((res) => {
        handleFetchOrder();
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const handleRemoveQuantityFromCart = (slug) => {
    authAxios
      .post(orderItemUpdateQuantityURL, { slug })
      .then((res) => {
        handleFetchOrder();
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleRemoveItem = (itemID) => {
    authAxios
      .delete(orderItemDeleteURL(itemID))
      .then((res) => {
        handleFetchOrder();
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <Container>
      <Header>Order Summary</Header>
      {error && (
        <Message
          error
          header="There was an error"
          content={JSON.stringify(error)}
        />
      )}
      {loading && (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      )}
      {data && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item #</Table.HeaderCell>
              <Table.HeaderCell>Item name</Table.HeaderCell>
              <Table.HeaderCell>Item price</Table.HeaderCell>
              {/* <Table.HeaderCell>Item quantity</Table.HeaderCell>
              <Table.HeaderCell>Total item price</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.order_items.map((orderItem, i) => {
              return (
                <Table.Row key={orderItem.id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>
                    {orderItem.item.title} - {renderVariations(orderItem)} - {renderCustomizations(orderItem)}
                  </Table.Cell>
                  {/* <Table.Cell>
                    ${orderItem.item.price}
                    </Table.Cell> */}
                  {/* <Table.Cell textAlign="center">
                    <Icon
                      name="minus"
                      style={{ float: "left", cursor: "pointer" }}
                      onClick={() =>
                        handleRemoveQuantityFromCart(orderItem.item.slug)
                      }
                    />
                    {orderItem.quantity}
                    <Icon
                      name="plus"
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() =>
                        handleAddToCart(
                          orderItem.item.slug,
                          orderItem.item_variations,
                          orderItem.customizations
                        )
                      }
                    />
                  </Table.Cell> */}
                  <Table.Cell>
                    {/* {orderItem.item.discount_price && (
                      <Label color="green" ribbon>
                        ON DISCOUNT
                      </Label>
                    )} */}
                    ${orderItem.price}
                    <Icon
                      name="trash"
                      color="red"
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() => handleRemoveItem(orderItem.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
            <Table.Row>
              <Table.Cell />
              <Table.Cell />
              {/* <Table.Cell /> */}
              <Table.Cell textAlign="right" colSpan="2">
                Order Total: ${data.total}
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <Link to="/checkout">
                  <Button floated="right" color="red">
                    Checkout
                  </Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null
});

export default connect(mapStateToProps)(OrderSummary);
