import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Container,
  Dimmer,
  Form,
  Grid,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Segment,
  Select,
  Divider,
  Sticky,
  List,
  Checkbox,
  Input
} from "semantic-ui-react";
import { productDetailURL, addToCartURL, calculatePriceURL, ProcessDesignURL } from "../constants";
import { fetchCart } from "../store/actions/cart";
import { authAxios } from "../utils";

const ProductDetail = ({ refreshCart }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [data, setData] = useState({
    image: "",
    title: "",
    category: "",
    discount_price: null,
    label: "",
    description: "",
    slug: "",
    variations: [],
    customizations: [],
  });
  const [formData, setFormData] = useState({});
  const [userOriginalImage, setUserOriginalImage] = useState(null);
  const [processedPreview, setProcessedPreview] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [designUploaded, setDesignUploaded] = useState(false);
  const { productID } = useParams();
  const navigate = useNavigate();
  const contextRef = useRef();

  useEffect(() => {
    handleFetchItem();
  }, [productID]);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const handleFetchItem = () => {
    setLoading(true);
    axios
      .get(productDetailURL(productID))
      .then((res) => {
        setData(res.data);

        const initialFormData = {};
        res.data.variations.forEach(variation => {
          const defaultItem = variation.item_variations.find(item => item.pretty_name === variation.default_value);
          if (defaultItem) {
            initialFormData[`variation_${variation.id}`] = defaultItem.id;
          }
        });
        res.data.customizations.forEach(customization => {
          initialFormData[`customization_${customization.id}`] = customization.default_value;
        });

        setFormData(initialFormData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const handleAddToCart = async (slug) => {
    setLoading(true);
    const variations = parseUVariations(formData);
    const customizations = parseCustomizations(formData);
    const total_price = pricing !== null ? pricing.total_dollars : 0;

    authAxios
      .post(addToCartURL, { slug, variations, customizations, total_price })
      .then((res) => {
        refreshCart();
        setLoading(false);
        setFormVisible(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const handleChange = (e, data) => {
    const { name, value, checked, type } = data || e.target;
    var actualValue = value;
    if (type === 'checkbox') {
      checked ? actualValue = "True" : actualValue = "False";
    }
    const updatedFormData = {
      ...formData,
      [name]: actualValue,
    };
    setFormData(updatedFormData);
    calculatePrice(updatedFormData);
  };

  const parseUVariations = (updatedFormData) => {
    return Object.entries(updatedFormData)
      .filter(([key, value]) => key.startsWith('variation_'))
      .map(([key, value]) => ({ id: key.replace('variation_', ''), value_id: value }));
  };

  const parseCustomizations = (updatedFormData) => {
    return Object.entries(updatedFormData)
      .filter(([key, value]) => key.startsWith('customization_'))
      .map(([key, value]) => ({ id: key.replace('customization_', ''), value: value }));
  };

  const calculatePrice = async (updatedFormData) => {
    try {
      const variations = parseUVariations(updatedFormData);
      const customizations = parseCustomizations(updatedFormData);
      const response = await axios.post(calculatePriceURL, {
        item_id: data.id,
        variations: variations,
        customizations: customizations,
      });
      setPricing(response.data.pricing);
    } catch (error) {
      console.error('Error calculating price:', error);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUserOriginalImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }

    // Start loading
    setLoading(true);

    // Upload the file to the backend
    const formDatFile = new FormData();
    formDatFile.append("file", file);

    await axios
      .post(ProcessDesignURL, formDatFile)
      .then((res) => {
        setProcessedPreview(res.data.processedPreview);
        setDesignUploaded(true);

        // Create a mapping from customization names to their IDs
        const customizationMap = data.customizations.reduce((map, customization) => {
          map[customization.name] = customization.id;
          return map;
        }, {});

        // Update formData with new values from res.data
        setFormData((prevFormData) => {
          const updatedFormData = { ...prevFormData };
          Object.keys(res.data).forEach((key) => {
            if (customizationMap[key]) {
              const newKey = `customization_${customizationMap[key]}`;
              updatedFormData[newKey] = res.data[key];
            }
          });
          calculatePrice(updatedFormData);
          return updatedFormData;
        });
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  // Combine variations and customizations, then sort by rank
  const combinedData = [
    ...data.variations.map((v) => ({
      type: 'variation',
      ...v,
    })),
    ...data.customizations.map((c) => ({
      type: 'customization',
      ...c,
    }))
  ].sort((a, b) => a.rank - b.rank);

  return (
    <Container>
      {error && (
        <Message
          error
          header="There were some errors with your submission"
          content={JSON.stringify(error)}
        />
      )}
      {loading && (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Image src="/images/wireframe/short-paragraph.png" />
        </Segment>
      )}
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column width={10}>
            <Card
              fluid
              image={processedPreview || userOriginalImage || data.image}
              header={data.title}
              meta={
                <React.Fragment>
                  {data.category}
                </React.Fragment>
              }
              description={data.description}
              extra={
                <React.Fragment>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="fileInput"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileInput">
                    <Button as="span" color="blue" fluid>
                      Upload Your Design
                    </Button>
                  </label>
                  <div style={{ margin: "15px 0" }} />
                  <Button
                    fluid
                    color="red"
                    floated="right"
                    icon
                    labelPosition="right"
                    onClick={handleToggleForm}
                  >
                    Customize
                    <Icon name="pencil alternate" />
                  </Button>
                </React.Fragment>
              }
            />
            {formVisible && (
              <React.Fragment>
                <Divider />
                <Form onSubmit={() => handleAddToCart(data.slug)}>
                  <Grid columns={2} divided>
                    {combinedData.map((item, index) => (
                      <Grid.Column key={item.id} width={8}>
                        <Form.Field>
                          {item.data_type === 'checkbox' ? <label>{" "}</label> : <label>{item.pretty_name}</label>}
                          {item.data_type === 'select' && (
                            <Select
                              type="text"
                              name={`${item.type}_${item.id}`}
                              onChange={handleChange}
                              placeholder={item.default_value}
                              fluid
                              selection
                              options={item.item_variations.map((variation) => ({
                                key: variation.id,
                                text: variation.pretty_name,
                                value: variation.id,
                              }))}
                              value={formData[`${item.type}_${item.id}`]}
                              disabled={!item.editable_by_user}
                            />
                          )}
                          {item.data_type === 'text' && (
                            <Input
                              type="text"
                              name={`${item.type}_${item.id}`}
                              onChange={handleChange}
                              placeholder={item.default_value}
                              value={formData[`${item.type}_${item.id}`]}
                              disabled={!item.editable_by_user}
                            />
                          )}
                          {item.data_type === 'checkbox' && (
                            <Checkbox
                              name={`${item.type}_${item.id}`}
                              onChange={handleChange}
                              label={item.pretty_name}
                              checked={formData[`${item.type}_${item.id}`]=== 'True'}
                              disabled={!item.editable_by_user}
                            />
                          )}
                          {item.data_type === 'number' && (
                            <Input
                              type="number"
                              name={`${item.type}_${item.id}`}
                              onChange={handleChange}
                              placeholder={item.default_value}
                              value={formData[`${item.type}_${item.id}`]}
                              disabled={!item.editable_by_user}
                            />
                          )}
                        </Form.Field>
                      </Grid.Column>
                    ))}
                  </Grid>

                  <div style={{ margin: "20px 0" }} />
                  <Form.Button primary>Add to Cart</Form.Button>
                </Form>
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            <div ref={contextRef}>
              <Sticky className="smooth-sticky" context={contextRef} offset={50}>
                <List>
                  <List.Item>
                    <List.Content>
                      <List.Header>Materials</List.Header>
                      <List.Description>${pricing !== null ? pricing.material_dollars : 0}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Process</List.Header>
                      <List.Description>${pricing !== null ? pricing.process_dollars : 0}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Special Items</List.Header>
                      <List.Description>${pricing !== null ? pricing.add_on_dollars : 0}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Total Price</List.Header>
                      <List.Description>${pricing !== null ? pricing.total_dollars : 0}</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Sticky>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  refreshCart: () => dispatch(fetchCart()),
});

export default connect(null, mapDispatchToProps)(ProductDetail);
