import React, { useEffect } from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment
} from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import { fetchCart } from "../store/actions/cart";

const CustomLayout = ({ authenticated, cart, loading, fetchCart, logout, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  return (
    <div>
      <Menu inverted>
        <Container>
          <Link to="/">
            <Menu.Item header>Home</Menu.Item>
          </Link>
          <Link to="/products">
            <Menu.Item header>Products</Menu.Item>
          </Link>
          {authenticated ? (
            <React.Fragment>
              <Menu.Menu position="right">
                <Link to="/profile">
                  <Menu.Item>Profile</Menu.Item>
                </Link>
                <Dropdown
                  icon="cart"
                  loading={loading}
                  text={`${cart !== null ? cart.order_items.length : 0}`}
                  pointing
                  className="link item"
                >
                  <Dropdown.Menu>
                    {cart !== null ? (
                      <React.Fragment>
                        {cart.order_items.map((order_item) => (
                          <Dropdown.Item key={order_item.id}>
                            {order_item.quantity} x {order_item.item.title}
                          </Dropdown.Item>
                        ))}
                        {cart.order_items.length < 1 ? (
                          <Dropdown.Item>No items in your cart</Dropdown.Item>
                        ) : null}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          icon="arrow right"
                          text="Checkout"
                          onClick={() => navigate("/order-summary")}
                        />
                      </React.Fragment>
                    ) : (
                      <Dropdown.Item>No items in your cart</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item header onClick={logout}>
                  Logout
                </Menu.Item>
              </Menu.Menu>
            </React.Fragment>
          ) : (
            <Menu.Menu position="right">
              <Link to="/login">
                <Menu.Item header>Login</Menu.Item>
              </Link>
              <Link to="/signup">
                <Menu.Item header>Signup</Menu.Item>
              </Link>
            </Menu.Menu>
          )}
        </Container>
      </Menu>

      {children}

      <Segment
        inverted
        vertical
        style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
      >
        <Container textAlign="center">
          <Grid divided inverted stackable>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 1" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 2" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 3" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header inverted as="h4" content="Footer Header" />
              <p>
                Extra space for a call to action inside the footer that could
                help re-engage users.
              </p>
            </Grid.Column>
          </Grid>

          <Divider inverted section />
          <Image centered size="mini" src="/logo.png" />
          <List horizontal inverted divided link size="small">
            <List.Item as="a" href="#">
              Site Map
            </List.Item>
            <List.Item as="a" href="#">
              Contact Us
            </List.Item>
            <List.Item as="a" href="#">
              Terms and Conditions
            </List.Item>
            <List.Item as="a" href="#">
              Privacy Policy
            </List.Item>
          </List>
        </Container>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.token !== null,
  cart: state.cart.shoppingCart,
  loading: state.cart.loading,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  fetchCart: () => dispatch(fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayout);
