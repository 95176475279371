import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

const DesktopContainer = ({ children }) => {
  const [fixed, setFixed] = useState(false);

  const showFixedMenu = () => setFixed(true);
  const hideFixedMenu = () => setFixed(false);

  return (
    <Visibility
      once={false}
      onBottomPassed={showFixedMenu}
      onBottomPassedReverse={hideFixedMenu}
    >
      {children}
    </Visibility>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

const MobileContainer = ({ children }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Segment}
        animation="overlay"
        onHide={handleSidebarHide}
        vertical
        visible={sidebarOpened}
      >
        {/* Add Sidebar content here if needed */}
      </Sidebar>
      <Sidebar.Pusher dimmed={sidebarOpened}>{children}</Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <div className="only-desktop">
      <DesktopContainer>{children}</DesktopContainer>
    </div>
    <div className="only-mobile">
      <MobileContainer>{children}</MobileContainer>
    </div>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => {
  const navigate = useNavigate(); // useNavigate must be inside a component

  return (
    <ResponsiveContainer>
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Welcome to Embroidery.us: Your Premier Source for Wire Embroidery in the USA
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                At Embroidery.us, we specialize in bringing your embroidery ideas to life with our state-of-the-art wire embroidery and technical embroidery services. Whether you're a student, a small business, or a large corporation, we are dedicated to providing top-notch manufacturing solutions right here in America. When you think of "Embroidery USA," think of Embroidery.us.
              </p>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Why Choose Embroidery.us?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                <b>Wire Embroidery Expertise:</b> With over 15 years of experience in the field, our PhD-level staff is uniquely qualified to handle your wire embroidery projects. Our specialized technique, known as tailored wire placement, ensures precision and quality of wire placement in every piece. Tailored wire placement involves embedding heating wires into fabric using precise embroidery and robotic stitching techniques, creating robust and efficient heating elements suitable for various applications.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                <b>No Software Needed:</b> Save time, money, and headaches of learning new embroidery software. Just bring your creative wire embroidery idea. We accept common vector formats such as SVG, AI, and DXF, making it easy for you to submit your designs without needing specialized software. If you have an embroidery file digitized, we accept these formats: PES, DST, EXP, JEF, VP3.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                <b>Made in America:</b> We take pride in manufacturing all our technical textiles within the USA. Our technical embroidery factory adheres to the highest standards, guaranteeing you receive superior products made right here at home. Embroidery USA is more than a slogan; it's a commitment to quality and domestic craftsmanship.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                <b>Confidentiality and Security:</b> Your ideas are safe with us. We maintain strict confidentiality and are willing to sign NDAs to protect your intellectual property. Our secure processes ensure that your innovative designs remain yours. We want to help your wire embroidery technical textiles grow.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                <b>Instant Quotes:</b> Our instant quote system allows you to get a quote for your wire embroidery project immediately, streamlining the process and saving you time. This efficient service ensures that you can plan your projects with ease and confidence knowing what the cost of embroidery is upfront.
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Image
                bordered
                rounded
                size="large"
                src="https://via.placeholder.com/400x300?text=Your+Image+Here"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button size="huge" onClick={() => navigate("/products")}>
                Instant Quote
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment style={{ padding: "0em" }} vertical>
        <Grid celled="internally" columns="equal" stackable>
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Our Services
              </Header>
              <Image
                bordered
                rounded
                size="medium"
                src="https://via.placeholder.com/300x200?text=Your+Image+Here"
              />
              <ul style={{ fontSize: "1.33em", textAlign: "left", marginTop: "1em" }}>
                <li><b>Wire Embroidery:</b> Perfect for heating elements, wearable design, smart textiles, and composite heating solutions. Our wire embroidery services are ideal for creating intricate patterns and designs that incorporate conductive materials for various applications.</li>
                <br />
                <li><b>Technical Embroidery Service:</b> We offer a wide range of technical embroidery services tailored to meet your specific needs. From heat-resistant mats to electric heating mats, our solutions are designed to withstand extreme conditions while providing optimal performance.</li>
                <br />
                <li><b>Heating Mats:</b> Our expertise in wire embroidery extends to creating efficient and reliable heating mats. These mats can be used for infrared heaters, heaters for composite wind blades, and other specialized heating applications. Our heating mats are designed to provide consistent and controlled heat, making them suitable for a variety of industrial and consumer uses.</li>
              </ul>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Detailed Applications
              </Header>
              <Image
                bordered
                rounded
                size="medium"
                src="https://via.placeholder.com/300x200?text=Your+Image+Here"
              />
              <ul style={{ fontSize: "1.33em", textAlign: "left", marginTop: "1em" }}>
                <li><b>Heat Resistant Mats:</b> These mats are designed to endure high temperatures and are perfect for environments where traditional materials would fail. Our heat-resistant mats are ideal for industrial applications that require durable and reliable heating solutions.</li>
                <br />
                <li><b>Electric Heating Mats:</b> Utilizing our tailored wire placement technique, these mats provide uniform heat distribution and are perfect for applications such as floor heating, heated seating, and more. Our electric heating mats are efficient and easy to install, offering a modern solution for residential and commercial heating needs.</li>
                <br />
                <li><b>Infrared Heaters:</b> Our wire embroidery technology enables the creation of advanced infrared heaters that are energy-efficient and provide excellent heat coverage. Infrared heaters are ideal for outdoor heating, spot heating in large spaces, and other specialized applications.</li>
                <br />
                <li><b>Heaters for Composite Wind Blades:</b> We specialize in creating heating solutions for composite materials, including wind blades. These heaters ensure that composite materials maintain their integrity and performance even in harsh conditions.</li>
                <br />
                <li><b>Heaters for Composites:</b> Our custom heaters for composites are designed to provide even heating across complex shapes and structures, ensuring consistent performance and durability.</li>
                <br />
                <li><b>Dew Heaters for Telescopes:</b> Our dew heaters are perfect for preventing condensation on telescopes, ensuring clear and uninterrupted viewing. These heaters are essential for amateur and professional astronomers alike.</li>
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em" }}>
            Our Process
          </Header>
          <Image
            bordered
            rounded
            size="large"
            src="https://via.placeholder.com/600x400?text=Your+Image+Here"
          />
          <ul style={{ fontSize: "1.33em", textAlign: "left", marginTop: "1em" }}>
            <li><b>Prototyping:</b> We offer low production numbers from 1-10 units, perfect for testing and refining your designs. Our prototyping process allows you to experiment with different materials and techniques to achieve the best results.</li>
            <br />
            <li><b>Small Batch Production:</b> For orders ranging from 10-500 units, we provide small batch production that balances quality and efficiency. This service is ideal for initial product launches and limited edition runs.</li>
            <br />
            <li><b>Large Batch Production:</b> For orders exceeding 500 units, our large batch production capabilities ensure that we can meet high-volume demands without compromising on quality. Our scalable processes allow us to handle large orders with precision and reliability.</li>
          </ul>
          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
            <a href="#case-studies">Case Studies</a>
          </Divider>
          <Header as="h3" style={{ fontSize: "2em" }}>
            Berry Act Compliance and Government Work
          </Header>
          <Image
            bordered
            rounded
            size="large"
            src="https://via.placeholder.com/600x400?text=Your+Image+Here"
          />
          <p style={{ fontSize: "1.33em", marginTop: "1em" }}>
            We are proud to be Berry Act compliant, ensuring that all our products meet the stringent requirements for materials and manufacturing processes. This compliance means that our products are truly "Embroidery Made in the USA." It also makes us an ideal partner for USA federal grants and government work. Our expertise in wire embroidery and technical textiles positions us to support a wide range of government projects and initiatives.
          </p>
          <Button as="a" size="large">
            Read More
          </Button>
          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
            <a href="#case-studies">Case Studies</a>
          </Divider>
          <Header as="h3" style={{ fontSize: "2em" }}>
            Why Embroidery USA?
          </Header>
          <Image
            bordered
            rounded
            size="large"
            src="https://via.placeholder.com/600x400?text=Your+Image+Here"
          />
          <p style={{ fontSize: "1.33em", marginTop: "1em" }}>
            Embroidery USA is synonymous with quality, innovation, and reliability. At Embroidery.us, we embody these values in every project we undertake. From our advanced wire embroidery techniques to our commitment to American manufacturing, we are dedicated to providing the best services and products to our clients.
          </p>
          <Button as="a" size="large">
            I'm Still Quite Interested
          </Button>
        </Container>
      </Segment>
    </ResponsiveContainer>
  );
};

export default HomepageLayout;
