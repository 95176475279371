import React from "react";
import { Routes, Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import HomepageLayout from "./containers/Home";
import ProductList from "./containers/ProductList";
import ProductDetail from "./containers/ProductDetail";
import OrderSummary from "./containers/OrderSummary";
import Checkout from "./containers/Checkout";
import Profile from "./containers/Profile";
import BlogList from "./containers/BlogList";
import BlogDetail from "./containers/BlogDetail";

const BaseRouter = () => (
  <Hoc>
    <Routes>
      <Route path="/products" element={<ProductList />} />
      <Route path="/products/:productID" element={<ProductDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/order-summary" element={<OrderSummary />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:postId" element={<BlogDetail />} />
      <Route path="/" element={<HomepageLayout />} />
    </Routes>
  </Hoc>
);

export default BaseRouter;
