import React, { useEffect, useState } from 'react';
import axios from "axios";

import { BlogListURL } from "../constants"; 

const BlogList = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get(BlogListURL)
            .then(response => setPosts(response.data))
            .catch(error => console.error('Error fetching blog posts:', error));
    }, []);

    return (
        <div>
            <h1>Blog</h1>
            <ul>
                {posts.map(post => (
                    <li key={post.id}>
                        <h2>{post.title}</h2>
                        <p>{post.content}</p>
                        {post.image && <img src={post.image} alt={post.title} />}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BlogList;
