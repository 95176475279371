import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Image,
  Item,
  Label,
  Loader,
  Message,
  Segment,
  Select
} from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { authAxios } from "../utils";

import {
  checkoutURL,
  orderSummaryURL,
  addCouponURL,
  addressListURL
} from "../constants";

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const OrderPreview = ({ data }) => (
  <React.Fragment>
    {data && (
      <React.Fragment>
        <Item.Group relaxed>
          {data.order_items.map((orderItem, i) => (
            <Item key={i}>
              <Item.Image size="tiny" src={`http://127.0.0.1:8000${orderItem.item.image}`} />
              <Item.Content verticalAlign="middle">
                <Item.Header as="a">{orderItem.quantity} x {orderItem.item.title}</Item.Header>
                <Item.Extra>
                  <Label>${orderItem.final_price}</Label>
                </Item.Extra>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>
                Order Total: ${data.total}
                {data.coupon && (
                  <Label color="green" style={{ marginLeft: "10px" }}>
                    Current coupon: {data.coupon.code} for ${data.coupon.amount}
                  </Label>
                )}
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </React.Fragment>
    )}
  </React.Fragment>
);

const CouponForm = ({ handleAddCoupon }) => {
  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddCoupon(e, code);
    setCode("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Coupon code</label>
        <input
          placeholder="Enter a coupon.."
          value={code}
          onChange={handleChange}
        />
      </Form.Field>
      <Button type="submit">Submit</Button>
    </Form>
  );
};

const CheckoutForm = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");
  
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    handleFetchOrder();
    handleFetchBillingAddresses();
    handleFetchShippingAddresses();
  }, []);

  const handleGetDefaultAddress = (addresses) => {
    const filteredAddresses = addresses.filter(el => el.default === true);
    return filteredAddresses.length > 0 ? filteredAddresses[0].id : "";
  };

  const handleFetchBillingAddresses = () => {
    setLoading(true);
    authAxios.get(addressListURL("B"))
      .then(res => {
        setBillingAddresses(res.data.map(a => ({
          key: a.id,
          text: `${a.street_address}, ${a.apartment_address}, ${a.country}`,
          value: a.id
        })));
        setSelectedBillingAddress(handleGetDefaultAddress(res.data));
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const handleFetchShippingAddresses = () => {
    setLoading(true);
    authAxios.get(addressListURL("S"))
      .then(res => {
        setShippingAddresses(res.data.map(a => ({
          key: a.id,
          text: `${a.street_address}, ${a.apartment_address}, ${a.country}`,
          value: a.id
        })));
        setSelectedShippingAddress(handleGetDefaultAddress(res.data));
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const handleFetchOrder = () => {
    setLoading(true);
    authAxios.get(orderSummaryURL)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 404) {
          navigate("/products");
        } else {
          setError(err);
          setLoading(false);
        }
      });
  };

  const handleAddCoupon = (e, code) => {
    e.preventDefault();
    setLoading(true);
    authAxios.post(addCouponURL, { code })
      .then(() => {
        setLoading(false);
        handleFetchOrder();
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const handleSelectChange = (e, { name, value }) => {
    if (name === "selectedBillingAddress") {
      setSelectedBillingAddress(value);
    } else if (name === "selectedShippingAddress") {
      setSelectedShippingAddress(value);
    }
  };

  const submit = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        setError(error.message);
        setLoading(false);
      } else {
        setError(null);
        authAxios.post(checkoutURL, {
          stripeToken: token.id,
          selectedBillingAddress,
          selectedShippingAddress
        })
          .then(() => {
            setLoading(false);
            setSuccess(true);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
          });
      }
    } else {
      console.log("Stripe is not loaded");
      setLoading(false);
    }
  };

  return (
    <div>
      {error && (
        <Message
          error
          header="There was some errors with your submission"
          content={JSON.stringify(error)}
        />
      )}
      {loading && (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Image src="/images/wireframe/short-paragraph.png" />
        </Segment>
      )}
      <OrderPreview data={data} />
      <Divider />
      <CouponForm handleAddCoupon={handleAddCoupon} />
      <Divider />
      <Header>Select a billing address</Header>
      {billingAddresses.length > 0 ? (
        <Select
          name="selectedBillingAddress"
          value={selectedBillingAddress}
          clearable
          options={billingAddresses}
          selection
          onChange={handleSelectChange}
        />
      ) : (
        <p>You need to <Link to="/profile">add a billing address</Link></p>
      )}
      <Header>Select a shipping address</Header>
      {shippingAddresses.length > 0 ? (
        <Select
          name="selectedShippingAddress"
          value={selectedShippingAddress}
          clearable
          options={shippingAddresses}
          selection
          onChange={handleSelectChange}
        />
      ) : (
        <p>You need to <Link to="/profile">add a shipping address</Link></p>
      )}
      <Divider />
      {billingAddresses.length < 1 || shippingAddresses.length < 1 ? (
        <p>You need to add addresses before you can complete your purchase</p>
      ) : (
        <React.Fragment>
          <Header>Would you like to complete the purchase?</Header>
          <CardElement />
          {success && (
            <Message positive>
              <Message.Header>Your payment was successful</Message.Header>
              <p>Go to your <b>profile</b> to see the order delivery status.</p>
            </Message>
          )}
          <Button
            loading={loading}
            disabled={loading}
            primary
            onClick={submit}
            style={{ marginTop: "10px" }}
          >
            Submit
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

const WrappedForm = () => (
  <Container text>
    <Elements stripe={stripePromise}>
      <div>
        <h1>Complete your order</h1>
        <CheckoutForm />
      </div>
    </Elements>
  </Container>
);

export default WrappedForm;
