import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  Label,
  Loader,
  Menu,
  Message,
  Segment,
  Select,
  Table
} from "semantic-ui-react";
import {
  countryListURL,
  addressListURL,
  addressCreateURL,
  addressUpdateURL,
  addressDeleteURL,
  userIDURL,
  paymentListURL
} from "../constants";
import { authAxios } from "../utils";

const UPDATE_FORM = "UPDATE_FORM";
const CREATE_FORM = "CREATE_FORM";

const PaymentHistory = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleFetchPayments();
  }, []);

  const handleFetchPayments = () => {
    setLoading(true);
    authAxios
      .get(paymentListURL)
      .then((res) => {
        setPayments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {payments.map((p) => (
          <Table.Row key={p.id}>
            <Table.Cell>{p.id}</Table.Cell>
            <Table.Cell>${p.amount}</Table.Cell>
            <Table.Cell>{new Date(p.timestamp).toUTCString()}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const AddressForm = ({ address, formType, countries, userID, activeItem, callback }) => {
  const [formData, setFormData] = useState({
    address_type: "",
    apartment_address: "",
    country: "",
    default: false,
    id: "",
    street_address: "",
    user: 1,
    zip: ""
  });
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (formType === UPDATE_FORM) {
      setFormData(address);
    }
  }, [address, formType]);

  const handleToggleDefault = () => {
    setFormData({
      ...formData,
      default: !formData.default
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = (e, { name, value }) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    if (formType === UPDATE_FORM) {
      handleUpdateAddress();
    } else {
      handleCreateAddress();
    }
  };

  const handleCreateAddress = () => {
    authAxios
      .post(addressCreateURL, {
        ...formData,
        user: userID,
        address_type: activeItem === "billingAddress" ? "B" : "S"
      })
      .then((res) => {
        setSaving(false);
        setSuccess(true);
        setFormData({ default: false });
        callback();
      })
      .catch((err) => {
        setError(err);
        setSaving(false);
      });
  };

  const handleUpdateAddress = () => {
    authAxios
      .put(addressUpdateURL(formData.id), {
        ...formData,
        user: userID,
        address_type: activeItem === "billingAddress" ? "B" : "S"
      })
      .then((res) => {
        setSaving(false);
        setSuccess(true);
        setFormData({ default: false });
        callback();
      })
      .catch((err) => {
        setError(err);
        setSaving(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit} success={success} error={error}>
      <Form.Input
        required
        name="street_address"
        placeholder="Street address"
        onChange={handleChange}
        value={formData.street_address}
      />
      <Form.Input
        required
        name="apartment_address"
        placeholder="Apartment address"
        onChange={handleChange}
        value={formData.apartment_address}
      />
      <Form.Field required>
        <Select
          loading={countries.length < 1}
          fluid
          clearable
          search
          options={countries}
          name="country"
          placeholder="Country"
          onChange={handleSelectChange}
          value={formData.country}
        />
      </Form.Field>
      <Form.Input
        required
        name="zip"
        placeholder="Zip code"
        onChange={handleChange}
        value={formData.zip}
      />
      <Form.Checkbox
        name="default"
        label="Make this the default address?"
        onChange={handleToggleDefault}
        checked={formData.default}
      />
      {success && (
        <Message success header="Success!" content="Your address was saved" />
      )}
      {error && (
        <Message error header="There was an error" content={JSON.stringify(error)} />
      )}
      <Form.Button disabled={saving} loading={saving} primary>
        Save
      </Form.Button>
    </Form>
  );
};

const Profile = ({ isAuthenticated }) => {
  const [activeItem, setActiveItem] = useState("billingAddress");
  const [addresses, setAddresses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userID, setUserID] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    handleFetchAddresses();
    handleFetchCountries();
    handleFetchUserID();
  }, [isAuthenticated, activeItem]);

  const handleItemClick = (name) => {
    setActiveItem(name);
    handleFetchAddresses();
  };

  const handleGetActiveItem = () => {
    if (activeItem === "billingAddress") {
      return "Billing Address";
    } else if (activeItem === "shippingAddress") {
      return "Shipping Address";
    } else if (activeItem === "paymentHistory") {
      return "Payment History";
    } else if (activeItem === "orderHistory") {
      return "Order History";
    } else if (activeItem === "activeQuotes") {
      return "Active Quotes";
    }
  };

  const handleFormatCountries = (countries) => {
    const keys = Object.keys(countries);
    return keys.map((k) => ({
      key: k,
      text: countries[k],
      value: k
    }));
  };

  const handleDeleteAddress = (addressID) => {
    authAxios
      .delete(addressDeleteURL(addressID))
      .then((res) => {
        handleCallback();
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleFetchUserID = () => {
    authAxios
      .get(userIDURL)
      .then((res) => {
        setUserID(res.data.userID);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleFetchCountries = () => {
    authAxios
      .get(countryListURL)
      .then((res) => {
        setCountries(handleFormatCountries(res.data));
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleFetchAddresses = () => {
    setLoading(true);
    authAxios
      .get(addressListURL(activeItem === "billingAddress" ? "B" : "S"))
      .then((res) => {
        setAddresses(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const handleCallback = () => {
    handleFetchAddresses();
    setSelectedAddress(null);
  };

  const renderAddresses = () => (
    <React.Fragment>
      <Card.Group>
        {addresses.map((a) => (
          <Card key={a.id}>
            <Card.Content>
              {a.default && (
                <Label as="a" color="blue" ribbon="right">
                  Default
                </Label>
              )}
              <Card.Header>
                {a.street_address}, {a.apartment_address}
              </Card.Header>
              <Card.Meta>{a.country}</Card.Meta>
              <Card.Description>{a.zip}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Button color="blue" onClick={() => handleSelectAddress(a)}>
                Update
              </Button>
              <Button color="red" onClick={() => handleDeleteAddress(a.id)}>
                Delete
              </Button>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      {addresses.length > 0 ? <Divider /> : null}
      {selectedAddress === null ? (
        <AddressForm
          activeItem={activeItem}
          countries={countries}
          formType={CREATE_FORM}
          userID={userID}
          callback={handleCallback}
        />
      ) : null}
      {selectedAddress && (
        <AddressForm
          activeItem={activeItem}
          userID={userID}
          countries={countries}
          address={selectedAddress}
          formType={UPDATE_FORM}
          callback={handleCallback}
        />
      )}
    </React.Fragment>
  );

  return (
    <Grid container columns={2} divided>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && (
            <Message error header="There was an error" content={JSON.stringify(error)} />
          )}
          {loading && (
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
              <Image src="/images/wireframe/short-paragraph.png" />
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Menu pointing vertical fluid>
            <Menu.Item
              name="Billing Address"
              active={activeItem === "billingAddress"}
              onClick={() => handleItemClick("billingAddress")}
            />
            <Menu.Item
              name="Shipping Address"
              active={activeItem === "shippingAddress"}
              onClick={() => handleItemClick("shippingAddress")}
            />
            <Menu.Item
              name="Payment history"
              active={activeItem === "paymentHistory"}
              onClick={() => handleItemClick("paymentHistory")}
            />
            <Menu.Item
              name="Order History"
              active={activeItem === "orderHistory"}
              onClick={() => handleItemClick("orderHistory")}
            />
            <Menu.Item
              name="Active Quotes"
              active={activeItem === "activeQuotes"}
              onClick={() => handleItemClick("activeQuotes")}
            />
          </Menu>
        </Grid.Column>
        <Grid.Column width={10}>
          <Header>{handleGetActiveItem()}</Header>
          <Divider />
          {activeItem === "paymentHistory" ? (
            <PaymentHistory />
          ) : (
            renderAddresses()
          )}
          {/* You can add more components here for "Order History" and "Active Quotes" if needed */}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null
});

export default connect(mapStateToProps)(Profile);
